<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-card-title class="headline">License for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Manage your license or add more users.
        </p>

        <p v-if="!event.org_license && (event.license === 'BASIC' || event.license === 'PRO' || event.license === 'ENTERPRISE')">
          <v-btn color="primary" @click="selectPlan(event.license)" x-large>{{ !this.event.max_participants ? "Purchase Users" : "Add Users" }}</v-btn>
        </p>
      </v-card-text>
      <v-container class="info-container grey lighten-5 py-0">
        <v-row>
          <v-col cols="12" sm="6">
            <div class="label">Support Plan</div>
            <div class="value">{{eventUtil.getSupportPlan()}}</div>
            
            <div class="label">Extended Duration</div>
            <div class="value">
              <span v-if="licenseInfo && licenseInfo.extended_duration">
                <p>
                  This event has an extended duration applied. Prices are adjusted accordingly.
                </p>
              </span>
              <span v-else class="text-muted">Not applied</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-alert v-if="isTrial" type="warning" tile prominent>
        <p>
          Trial events cannot be upgraded to paid events. Please start a new event instead.
        </p>
        <p>
          <v-btn :to="{name: 'eventmanagerCreate', params: {}}">Create New Event</v-btn>
        </p>
      
      </v-alert>

      <v-alert v-if="event.org_license" type="info" tile prominent>
        <p>
          This event is part of the <strong>{{event.org.name}}</strong> organization. License is automatically managed through the organizational subscription.
        </p>
        <p>
          <v-btn :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Manage Subscription</v-btn>
        </p>
      </v-alert>
      <v-alert v-else-if="eventUtil.isFreePlan() && event.org" type="info" tile prominent>
        <p>
          Purchase options below are for one-time events only. Rather enable a subscription for ongoing events?
        </p>
        <p>
          <v-btn :to="{name: 'orgmanagerSubscription', params: {id: event.org.id}}">Enable Subscription</v-btn>
        </p>
      </v-alert>
      


      <v-alert v-if="status == 'refresh' && paymentStatus && paymentStatus.status == 'OK'" type="success" tile>
        <p>Thanks! Your payment was successfully processed. Please note that it can take a few minutes until your credits are updated.</p>
        <v-btn :to="{name: 'eventmanagerView', params: {id: event.id}}">Back to Event</v-btn>
      </v-alert>
      <v-alert v-else-if="status == 'cancelled' || (paymentStatus && paymentStatus.status == 'ERROR')" type="error" tile>
        Payment was cancelled or unsuccessful.
      </v-alert>
      <v-alert v-else-if="status == 'refresh'" type="info" tile>
        <v-progress-circular indeterminate :size="25" :width="5" color="white" class="mr-4"></v-progress-circular>
        Loading payment status... One minute...
      </v-alert>

      <div v-if="!paymentStatus && !status">
        <v-card-title class="subtitle">
          <span class="mr-2">Current License Plan</span>
          <span class="mr-2 font-weight-bold">{{ event.license | license }}</span>
        </v-card-title>
        <v-card-text v-if="licenseInfo">
          <v-alert v-if="!licenseInfo.valid" type="error" prominent>
            <p>Invalid license. No new users can join this challenge until new credits are purchased.</p>
            <p v-if="event.license !== 'FREE'">
              <v-btn @click="selectPlan(event.license)">Purchase User Credits</v-btn>                            
            </p>
          </v-alert>
          <div class="d-flex flex-row">
            <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
            <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
          </div>
          <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} users have joined. Actual number of joined users might have a slight processing delay.</span>
        </v-card-text>
      </div>

      <div class="align-center">
          <div class="text-muted">Currency:</div>
          <v-btn-toggle v-model="currency" mandatory class="multiline-btns" >
            <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
              {{cur}}
            </v-btn>
          </v-btn-toggle>
      </div>

      <v-row v-if="!eventUtil.isProPlan()" class="mx-2 pricing-details">
        
        <v-col cols="12" sm="6" lg="4">
          <v-sheet elevation="3" outlined color="white lighten-1" class="pa-4">
            <h3 class="text-center">Starter</h3>
            <p>All the good stuff you need to host a successful fitness challenge.</p>

            <v-btn v-if="event.org_license" text :to="{name: 'orgmanagerView', params: {id: event.org.id}}">Manage Subscription</v-btn>
            <v-btn v-else-if="event.license === 'BASIC'" outlined color="green" @click="selectPlan('product-event-basic')">Add Users</v-btn>
            <v-btn v-else-if="event.license === 'FREE'" :disabled="isTrial" outlined color="green" @click="selectPlan('product-event-basic')">Select Standard</v-btn>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-basic' + extendedDurationPostfix).prices[currency]}}</span><span class="small">per user <br/> per event</span>
            <hr/>
             <ul class="check-list">
              <li>All challenge modes</li>
              <li>All device integrations</li>
              <li>Team leaderboards</li>
              <li>Virtual course maps</li>
              <li>Self-service dashboard to manage events</li>
            </ul>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-sheet elevation="5" outlined color="white lighten-0" class="pa-4 popular">
            <h3 class="text-center">Pro <v-chip small outlined color="black">Most Popular</v-chip></h3>
            <p>Advanced features to host engaging events for larger teams.</p>
            <v-btn v-if="event.org_license" text :to="{name: 'orgmanagerView', params: {id: event.org.id}}">Manage Subscription</v-btn>
            <v-btn v-else-if="event.license === 'PRO'" outlined color="green" @click="selectPlan('product-event-pro')">Add Users</v-btn>
            <v-btn v-else-if="event.license === 'FREE'" :disabled="isTrial" outlined color="green" @click="selectPlan('product-event-pro')">Select Pro</v-btn>
            <v-btn v-else-if="event.license === 'BASIC'" :disabled="isTrial" outlined color="green" @click="upgradeToPro()">Upgrade to Pro</v-btn>
            <!-- <v-btn v-else :disabled="isTrial" color="green" @click="selectPlan('product-event-pro')">Select Pro</v-btn> -->
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-pro' + extendedDurationPostfix).prices[currency]}}</span><span class="small">per user<br/>per event</span>
            <hr/>
            <div class="align-left">All from <strong>Starter</strong>, plus:</div>
            <ul class="check-list">
              <li>Customizable branding</li>
              <li>Automatic step conversions</li>
              <li>Microsoft Teams Integration</li>
              <li>Slack Integration</li>
              <li>Excel Report Export</li>
              <li>Multiple co-admins</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-sheet elevation="3" outlined color="white lighten-0" class="pa-4">
            <h3 class="text-center">Enterprise</h3>
            <p>Enhanced security, SSO, integrations, security checklists, and DPAs.</p>
            <v-btn v-if="event.org_license" text :to="{name: 'orgmanagerView', params: {id: event.org.id}}">Manage Subscription</v-btn>
            <v-btn v-else-if="event.license === 'ENTERPRISE'" outlined color="green" @click="selectPlan('product-event-enterprise')">Add Users</v-btn>
            <!-- <v-btn v-else :disabled="isTrial" outlined color="green" @click="selectPlan('product-event-enterprise')">Select Enterprise</v-btn> -->
            <v-btn v-else-if="event.license === 'FREE'" :disabled="isTrial" outlined color="green" @click="selectPlan('product-event-enterprise')">Select Enterprise</v-btn>
            <v-btn v-else text color="green" to="/contact">Talk To Us</v-btn>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-enterprise' + extendedDurationPostfix).prices[currency]}}</span><span class="small">per user<br/>per event</span>
            <!-- <span class="large">Contact Us</span> -->
            <hr/>
            <div class="align-left">All from <strong>PRO</strong>, plus:</div>
            <ul class="check-list">
              <li>Single Sign-On</li>
              <li>Custom DPA</li>
              <li>Security Checklists</li>
              <li>API and Zapier support</li>
              <li>Advanced security features</li>
            </ul>
          </v-sheet>
        </v-col>
      </v-row>
      <div v-if="!eventUtil.isProPlan()" class="align-center">
        <v-btn outlined rounded color="primary" to="/pricing" target="_blank">Show detailed plan comparison</v-btn>
      </div>

      <v-card-text class="mt-8">
        <h3>Add-Ons</h3>
        <v-sheet v-if="canEnableExtendedDuration" elevation="3" color="grey lighten-4 mb-8" class="px-4 py-1 pricing-details">
          <h3>Extended Duration</h3>
          <p>Upgrade to a maximum duration of 100 days instead of the default 45 days. Looking for event longer events? Consider our <router-link to="/pricing">Unlimited plans</router-link></p>
          <hr/>
          <v-row>
            <v-col class="grow">
              <span class="small">Per user fees will double compared with the pricing shown on our <router-link to="/pricing">pricing page</router-link>.</span>
            </v-col>
            <v-col class="shrink">
              <v-btn large color="primary" class="my-0" @click="enableExtendedDuration">Enable</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="3" color="grey lighten-4 mb-8" class="px-4 py-1 pricing-details">
          <h3>Setup-Service Package</h3>
          <p>Add the Setup-Service Package to have us do the configuration for your event plus an onboarding session.</p>
          <hr/>
          <v-row>
            <v-col class="grow">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-setup-service').prices[currency]}}</span><span class="small">one-time<br/>per event</span>
            </v-col>
            <v-col class="shrink">
              <v-btn large color="primary" class="my-0" @click="purchaseProduct('product-setup-service')">Purchase Now</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="3" color="grey lighten-4 mb-8" class="px-4 py-1 pricing-details">
          <h3>Full-Service Package</h3>
          <p>Add the Full-Service Package for additional support during the design, execution, and wrap-up of your event.</p>
          <hr/>
          <v-row>
            <v-col class="grow">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-full-service').prices[currency]}}</span><span class="small">one-time<br/>per event</span>
            </v-col>
            <v-col class="shrink">
              <v-btn large color="primary" class="my-0" @click="purchaseProduct('product-full-service')">Purchase Now</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>

    </v-card>   
    <v-dialog v-if="product" v-model="showQuantityDialog" max-width="400">
      <v-card>
        <v-card-title>{{ product.name }}</v-card-title>
        <v-card-text>
          <p>How many users credits do you want to purchase? The new credits will added on top of your current credits. You can always add more later on. Minimum purchase quantity is 10.</p>
          <!-- <p>Please note that you will see the name <strong>sodisp</strong> on the payment page and payment receipt. This is because {{ tenant.name }} is powered by the Sodisp Challenge Platform.</p> -->
          <v-text-field v-if="false" v-model="product.license" disabled label="License Type"/>
          <v-text-field v-if="false" v-model="pricePerUser" disabled :label="`Price per user (${currency})`"/>
          <v-text-field v-model.number="quantity" @change="ensureMinQuantity" prepend-icon="fa-user-plus" type="number" min="10" :label="`Number of users`" :hint="`At ${currency} ${pricePerUser} per user.`" persistent-hint/>
          <v-text-field v-if="!eventUtil.isFreePlan() && licenseInfo && licenseInfo.max_capacity > 0" disabled label="Total Users after purchase" :value="licenseInfo.max_capacity+quantity" prepend-icon="fa-users" class="mt-4" />
      

          <v-input v-if="eventUtil.isFreePlan() || !licenseInfo || licenseInfo.max_capacity === 0" prepend-icon="fa-hands-helping" class="mt-4 input-row" label="Support Package" hide-details>
            <template>
              <div v-if="lockedServicePlan">
                <strong>{{ products.find(x=>x.key=='product-setup-service').name }}</strong> 
                ({{currency}} {{products.find(x=>x.key=='product-setup-service').prices[currency]}})
              </div>
              <v-btn-toggle v-else v-model="servicePlanProductKey" mandatory class="multiline-btns">
                <v-btn :value="null" small :disabled="product.key == 'product-event-enterprise'">
                  Self-Service<br/>Free
                </v-btn>
                <v-btn value="product-setup-service" small :disabled="product.key == 'product-event-enterprise'">
                  Setup-Service<br/>{{currency}} {{products.find(x=>x.key=='product-setup-service').prices[currency]}}
                </v-btn>
                <v-btn value="product-full-service" small :disabled="product.key == 'product-event-enterprise'">
                  Full-Service<br/>{{currency}} {{products.find(x=>x.key=='product-full-service').prices[currency]}}
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-input>
          <p v-if="product.key == 'product-event-enterprise'" class="ml-8 mt-2"><v-icon>fal fa-info-circle</v-icon> Enterprise plans require the Full-Service support plan.</p>

          <v-text-field v-model="total" prepend-icon="fa-tags" class="my-2" disabled :prefix="currency" label="Checkout total" hide-details/>
          <v-btn large block color="primary" class="mt-4" @click="purchasePlan" :disabled="isTrial || $store.getters.isLoading" :loading="$store.getters.isLoading">Start Purchase</v-btn>
          <p class="text-muted mt-2">Secure payment using Stripe.</p>
          <div v-if="(eventUtil.isFreePlan() || (this.licenseInfo && this.licenseInfo.max_capacity === 0)) && event.license !== product.license">
            <v-divider class="mb-4" />
            <p>Not yet ready to invite users but want to use the {{ product.name}} features? You can add users when the event is ready to go live.</p>
            <v-btn large block outlined color="primary" class="mt-4" @click="enablePlan" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Enable Plan Features</v-btn>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog v-if="canEnableExtendedDuration" v-model="showExtendedDurationDialog" max-width="400">
      <v-card>
        <v-card-title>Extended duration</v-card-title>
        <v-card-text>
          <p>Enable extended duration for events lasting up to 100 days.</p>
          <p>Per user fees will double compared with the pricing shown on our pricing page.</p>
          <v-btn large block color="primary" class="mt-4" @click="enableExtendedDuration" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Enable</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>


    <div class="secondary white--text py-6 py-md-12 mt-4">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Questions? We're here to help.</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

     <div id="page-customers" class="mt-0 blob-bg white--text mt-8">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>
          <v-col class="text-center" cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0">
              <strong>{{siteData.stats.total_events | int}}</strong> have been created already by {{siteData.stats.total_orgs | int }} organizations. Join today!
            </p>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>

          </v-col>
        </v-row>
      </div>
    </div>

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import checkoutService from "@/services/checkoutService";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import Header from './_Header.vue'
import store from "@/store";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "License",
  components: {
    Header,
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      siteData: siteData,
      licenseInfo: null,
      quantity: 10,
      servicePlanProductKey: null,
      showQuantityDialog: false,
      showExtendedDurationDialog: false,
      status: null,
      paymentStatus: null,
      product: null,
      products: siteData.products,
      currency: 'USD',
      currencies: siteData.currencies,
      lockedServicePlan: false,
    };
  },
  async mounted() {
    this.status = this.$route.query.status;
    await this.loadState();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadState();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.selectedLicense = this.event.license;
      await this.getLicenseInfo();

      if (this.eventUtil.hasMeta('requested.currency')) {
        this.currency = this.eventUtil.getMetaStringValue('requested.currency').toUpperCase();
      }
      if (this.eventUtil.hasMeta('requested.count') || this.eventUtil.hasMeta('requested.support')) {
        var countMeta = this.eventUtil.getMeta('requested.count');
        if (countMeta) {
          this.quantity = countMeta.val_int;
        }
        var supportMeta = this.eventUtil.getMeta('requested.support');
        if (supportMeta && supportMeta.val_str != 'self-service') {
          this.servicePlanProductKey = `product-${supportMeta.val_str}`;
          this.lockedServicePlan = true;
        }
        this.selectPlan(this.event.license);
      }
    },

    async loadState() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
        if (this.status === 'refresh') {
          await this.getStatus();
        }
      }
    },

    async getStatus() {
      this.paymentStatus = (await checkoutService.getEventStatus(this.event.id)).data;
      
      if (this.paymentStatus.status === 'INFO') {
        // schedule a next refresh in a few seconds
        setTimeout(this.getStatus, 5 * 1000);
      }
      else if (this.paymentStatus.status === 'OK') {
        this.$analytics.event('purchase', {
          currency: 'USD',
          value: this.paymentStatus.value,
          transaction_id: this.paymentStatus.id,
        });

        await this.getLicenseInfo();
        // also schedule a refresh in a few seconds to give the webhook call some time to arrive
        setTimeout(this.getLicenseInfo, 2 * 1000);
      }
    },

    async getLicenseInfo() {
      this.licenseInfo = (await eventManagerService.getLicenseStatus(this.event.id)).data;
    },

    ensureMinQuantity() {
      if (this.event && this.event.license && this.event.license !== 'FREE' && this.licenseInfo && this.licenseInfo.max_capacity > 0) {
        // allow small increments for existing events who already have a license
        return;
      }
      if (this.quantity < 10) {
        this.quantity = 10;
      }
    },

    async selectPlan(productKey) {
      console.log('Opening add user dialog', productKey);
      var currentExtendedDuration = this.licenseInfo && this.licenseInfo.extended_duration;
      this.product = this.products.find(x => x.key === productKey + this.extendedDurationPostfix) || this.products.find(x => x.license === productKey && x.type == 'product' && (!currentExtendedDuration || x.extended_duration === currentExtendedDuration));
      if (productKey=='product-event-enterprise') {
        this.servicePlanProductKey = 'product-full-service';
      }
      console.log('Opening add user dialog', productKey, 'product', this.product, this.servicePlanProductKey, 'extended:', currentExtendedDuration);
      this.showQuantityDialog = true;
    },
    async upgradeToPro() {
      if (this.licenseInfo && this.licenseInfo.max_capacity === 0) {
        await eventManagerService.upgradeLicense(this.event.id);
        await this.getEvent(this.event.id);
        await this.eventUtil.refresh();
      }
      else {
        const request = {
          currency: this.currency,
        };

        this.isLoading = true;
        const response = (await checkoutService.createEventUpgradeSession(this.event.id, request)).data;
        this.isLoading = false;
        window.location.href = response.url
      }
      //this.product = this.products.find(x => x.key === productKey) || this.products.find(x => x.license === productKey && x.type == 'product');
      //this.showQuantityDialog = true;
    },
    async useFree() {
      //this.selectedLicense = license;
      //this.showQuantityDialog = true;
    },
    async enableExtendedDuration() {
      var response = await eventManagerService.enableExtendedDuration(this.event.id);
      if (response.data.status == 'OK') {
        await this.getEvent(this.event.id);
        await this.eventUtil.refresh();
        this.showExtendedDurationDialog = false;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },
    async enablePlan() {
      var response = await eventManagerService.applyLicense(this.event.id, this.product.license);
      if (response.data.status == 'OK') {
        await this.getEvent(this.event.id);
        await this.eventUtil.refresh();
        this.showQuantityDialog = false;
      }
      else {
        this.$helpers.toastResponse(this, response.data);
      }
    },
    async purchasePlan() {
      const request = {
        product: this.product.key,
        currency: this.currency,
        //license: this.selectedLicense,
        initial_quantity: this.quantity,
        service_plan_product: this.servicePlanProductKey,
        event_id: this.event.id,
      };
      this.$analytics.event('begin_checkout', {
        currency: 'USD',
        value: this.total,
      });

      this.isLoading = true;
      const response = (await checkoutService.createEventPaymentSession(request)).data;
      this.valid = true;
      this.isLoading = false;
      window.location.href = response.url

      /*store.commit('INIT', { 
        event: this.event,
        license: this.selectedLicense,
      });
      this.$router.push({name: 'checkoutCart'});*/
    },
    async purchaseProduct(productKey) {
      var product = this.products.find(x => x.key === productKey);
      var currency = this.currency || 'USD';
      var price = product.prices[currency];
      console.log('Starting purchase', product, 'currency', currency, 'price', price);
      const request = {
        product: productKey,
        initial_quantity: 1,
        coupon: this.coupon,
        currency: this.currency,
        event_id: this.event.id,
      };
      this.$analytics.event('begin_checkout', {
        currency: currency,
        value: price,
      });

      this.isLoading = true;
      const response = (await checkoutService.createEventProductSession(request)).data;
      this.valid = true;
      this.isLoading = false;
      window.location.href = response.url
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `License`, disabled: true },
      ];
    },

    canUpgrade() {
      return !this.isTrial;
    },
    canEnableExtendedDuration() {
      if (this.licenseInfo && this.licenseInfo.extended_duration) {
        return false;
      }
      return this.eventUtil.isFreePlan() || (this.licenseInfo && this.licenseInfo.max_capacity == 0);
    },
    isTrial() {
      return this.event && this.event.id.startsWith('trial-');
    },
    extendedDurationPostfix() {
      if (this.licenseInfo && this.licenseInfo.extended_duration) {
        return "-" + this.licenseInfo.extended_duration.toLowerCase();
      }
      return "";
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },

    pricePerUser() {
      if (!this.product || !this.currency) {
        return 0;
      }
      return this.product.prices[this.currency];
    },
    priceServicePlan() {
      if (!this.servicePlanProductKey || !this.currency) {
        return 0;
      }
      return this.products.find(x => x.key === this.servicePlanProductKey).prices[this.currency];
    },
    total() {
      return (this.quantity * this.pricePerUser) + (this.priceServicePlan);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .pricing-details {
    .v-sheet { border-radius: 10px !important; text-align: center;; }
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .small { font-size: 75%; display: inline-block; text-align: left;}
    .large { font-size: 200%;}
    .large + .small { margin-left: 5px; }
    .popular { border: solid 5px #00B46E !important; }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
</style>

